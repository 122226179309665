import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import get from "lodash/get";
import { Layout } from "../components/mdxLayout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Button } from '../components/button';
import SEO from '../components/seo';
import { IconBackground } from '../components/IconBackground';
export const GetInTouchCta = () => <div className="text-xl text-purple-light mt-8 bg-violet-light p-4 rounded-xl w-full">
    <strong>Want to learn more? </strong>
    Schedule a quick 15 minute call with our team
    <div className="text-normal mt-4">
      <a href="https://calendly.com/amy-try/15min" target="_blank">
        <Button mdxType="Button">
          Get in touch
        </Button>
      </a>
    </div>
  </div>;
export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      author {
        name
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        }
      }
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      heroImage {
        title
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
        body
        childMarkdownRemark {
          html
          rawMarkdownBody
          timeToRead
        }
      }
      tags
      description {
        childMarkdownRemark {
          excerpt
        }
      }
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  GetInTouchCta,
  pageQuery,
  _frontmatter
};

const MDXLayout = ({
  children,
  data
}) => <Layout>
    <SEO title={data.contentfulBlogPost.title} description={data.contentfulBlogPost.description.childMarkdownRemark.excerpt} image={data.contentfulBlogPost.heroImage.resize.src} article={true} />
    <div className="bg-grape">
      <div className="relative overflow-hidden">
        <div className="text-white opacity-10">
          <IconBackground />
        </div>
        <div className="relative pb-16 sm:pb-18">
          <div className="mt-16 mx-auto max-w-xl xl:max-w-2xl">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-white">
                {data.contentfulBlogPost.title}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-white py-12 shadow-overlay">
      {children}
    </div>
  </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <section>
  <div className="mx-auto max-w-xl xl:max-w-2xl p-4 lg:p-0">
    <div className="flex-1 p-6 flex flex-col justify-between">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <span className="sr-only">{props.data.contentfulBlogPost.author.name}</span>
          <GatsbyImage className="h-12 w-12 rounded-full" image={getImage(props.data.contentfulBlogPost.author.image)} alt={props.data.contentfulBlogPost.author.name ?? 'Photo of Author'} mdxType="GatsbyImage" />
        </div>
        <div className="ml-3 prose">
          <div className="text-md font-bold text-purple">
            {props.data.contentfulBlogPost.author.name}
          </div>
          <div className="flex space-x-1 text-sm">
            <time dateTime={props.data.contentfulBlogPost.rawDate}>
              {props.data.contentfulBlogPost.publishDate}
            </time>
            <span aria-hidden="true">
              &middot;
            </span>
            <span>
              {props.data.contentfulBlogPost.body.childMarkdownRemark.timeToRead} minute read
            </span>
          </div>
        </div>
      </div>
    </div>
    <GatsbyImage className="w-full mt-4 mb-8 rounded-lg" image={getImage(props.data.contentfulBlogPost.heroImage)} alt={props.data.contentfulBlogPost.title} mdxType="GatsbyImage" />
    <div className="prose" dangerouslySetInnerHTML={{
          __html: props.data.contentfulBlogPost.body.childMarkdownRemark.html
        }} />
    <p className="mx-auto px-4 text-xl md:text-2xl font-bold text-center max-w-4xl my-8">Thanks for reading!</p>
    <GetInTouchCta mdxType="GetInTouchCta" />
  </div>
    </section>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      